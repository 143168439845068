import { adapt_tokko_development } from "../../helpers/helper.tokko.jsx"
import { middlewareTokkoApi } from "../middlewareTokkoApi.js"

const developmentExtended = middlewareTokkoApi.injectEndpoints({
  endpoints: builder => ({
    getDevelopments: builder.query({
      query: API_KEY => ({
        url: `developments/search?cache=1`,
        headers: {
          "X-TOKKO-KEY": API_KEY,
        },
        method: "GET",
      }),
      transformResponse: ({ objects }) =>
        objects
          .sort((a, b) => b.id - a.id)
          .map(o => adapt_tokko_development(o)),
    }),
    getDevelopmentDetail: builder.query({
      query: ({ API_KEY, DEVELOPMENT_ID }) => ({
        url: `developments/${DEVELOPMENT_ID}?cache=0`,
        headers: {
          "X-TOKKO-KEY": API_KEY,
        },
        method: "GET",
      }),
    }),
    getUnities: builder.query({
      query: ({ API_KEY, DEVELOPMENT_ID }) => ({
        url: `properties/search?development_id=${DEVELOPMENT_ID}&limit=1000&cache=0&save=1`,
        headers: {
          "X-TOKKO-KEY": API_KEY,
        },
        method: "GET",
      }),
      transformResponse: ({ objects }) => objects,
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetDevelopmentsQuery,
  useGetDevelopmentDetailQuery,
  useGetUnitiesQuery,
} = developmentExtended
