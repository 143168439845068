import { useStaticQuery, graphql } from "gatsby"
import { useGetDevelopmentDetailQuery as useGetDevelopmentsTokkoQuery } from "../redux/middlewareTokkoApi/developments"
import { useGetDevelopmentQuery as useGetDevelopmentsMediacoreQuery } from "../redux/mediacore/emprendimientos"

function useQueryDevelopment(id) {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys {
          tokko
        }
        env {
          CLIENTID
        }
      }
    }
  `)

  const {
    data: allDevelopmentData,
    isLoading,
    isError,
    isFetching
  } = realEstate.keys.tokko //EDIT
    ? useGetDevelopmentsTokkoQuery({"API_KEY":realEstate.keys.tokko,"DEVELOPMENT_ID":id})
    : useGetDevelopmentsMediacoreQuery({
        CLIENT_ID: realEstate.env.CLIENTID,
        DEVELOPMENT_ID: id
      })

  return { allDevelopmentData, isLoading, isError, isFetching }
}

export default useQueryDevelopment
