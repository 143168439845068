import React, { useEffect } from "react"
import Seo from "../../../components/seo"
import Layout from "../../../components/layout"

import Main from "../../../components/Emprendimiento/main"
import About from "../../../components/Emprendimiento/about"
import Units from "../../../components/Emprendimiento/units"

import { connect } from "react-redux"

import { useParams } from "@reach/router"
import {
  getDevelopmentAction,
  getUnitsDevelopmentAction,
} from "../../../redux/developmentsDucks"
import {
  getName,
  getFakeAddress,
  getLocation,
} from "../../../helpers/helper.developments"
import { getCover } from "../../../helpers/helper.rendering"
import loadable from "@loadable/component"
import { useGetDevelopmentDetailQuery } from "../../../redux/middlewareTokkoApi/developments"
import { graphql, navigate, useStaticQuery } from "gatsby"
import useQueryDevelopment from "../../../hooks/useQueryDevelopment"

// const Main = loadable(() => import('../../../components/Emprendimiento/main'))
const isBrowser = typeof window !== "undefined"

const Development = props => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys {
          tokko
        }
        env{
          CLIENTID
        }
      }
    }
  `)

  const { id } = props

  const { allDevelopmentData, isLoading } = useQueryDevelopment(id)

  return (
    <Layout development={allDevelopmentData} preloader={true}>
      <Seo
        title={"Emprendimiento "}
        meta={"Encontrá el emprendimiento que estabas buscando. "}
        description={"Encontrá el emprendimiento que estabas buscando. "}
      />
      <Main id={id} />
      <About id={id} />
      <Units id={id} />
    </Layout>
  )
}
export default connect(state => ({}), null)(Development)
