import React, { useEffect, useRef, useState } from "react"
import OwlCarousel from "react-owl-carousel3"
import { connect } from "react-redux"
import Breadcrumbs from "../breadcrumbs"
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox"
import { graphql, useStaticQuery } from "gatsby"
import SliderComponent from "../slider.jsx"

//Helpers
import {
  getCountUnities,
  getMinAndMaxPrice,
} from "../../helpers/helper.unities"
import {
  getLocation,
  getStatus,
  getContructionDate,
  getName,
  getTitle,
  getFakeAddress,
  getType,
} from "../../helpers/helper.developments"
import {
  getPhotos,
  getDescription,
  getQuality,
  getVideos,
} from "../../helpers/helper.rendering"
import {
  getTour,
  getLastVideo,
  getTours,
  getBluePrints,
  getTags,
} from "../../helpers/helper.properties"
// import ButtonBox from './openLightBox';

import Loading from "../../components/Loading"
// import Slider from 'react-slick';
import Slider from "react-slick"
import FilesPro from "./files-pro"
import {
  useGetDevelopmentDetailQuery,
  useGetUnitiesQuery,
} from "../../redux/middlewareTokkoApi/developments"
import { setTogglerImages } from "../../redux/propertiesDucks"
import { useGetDevelopmentQuery } from "../../redux/mediacore/emprendimientos"
import useQueryDevelopment from "../../hooks/useQueryDevelopment"
import useQueryUnities from "../../hooks/useQueryUnities"

const title_extras = ["Servicios", "Amenities", "Generales"]

const About = ({ development, id, unities, loading, dispatch }) => {
  const [positionSlider, setPositionSlider] = useState(1)
  const sliderRef = useRef()
  const slider = useRef()
  const { openLightbox, closeLightbox } = useLightbox()

  const [MapComponent, setComponent] = useState(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const importedComponent = require("../map")

      setComponent(importedComponent.default)
    }
  }, [])

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        name
        template_version
        proFeatures {
          mediaBar
        }
        design {
          icons
        }
        keys {
          tokko
        }
        env {
          CLIENTID
        }
      }
    }
  `)

  const {
    design: { icons },
    proFeatures: { mediaBar },
  } = realEstate
  const MediaPro = realEstate.template_version === "PRO" ? true : false
  const iconsStyle =
    icons === "Solid" || icons === "Light" ? `-${icons.toLowerCase()}` : ""

  const options = {
    settings: {
      overlayColor: "rgba(0, 0, 0, 0.9)",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
      disableWheelControls: true,
    },
    buttons: {
      backgroundColor: "rgba(0, 0, 0, 0.9)",
      iconColor: "rgba(255,255,255, 1)",
    },
    caption: {
      captionColor: "#000",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
  }

  const settingsSlick = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const { allDevelopmentData, isLoading } = useQueryDevelopment(id)
  const { allUnitiesData, isLoading: isLoadingUnities } = useQueryUnities(id)

  const getParkingCount = parking_lot => {
    let count = 0
    for (let index = 0; index < parking_lot?.length; index++) {
      const parking = parking_lot[index]
      count += parking.parking_lot_amount
    }
    return count
  }

  return (
    <section className={"ficha-about dev " + (isLoading ? "skeleton" : "")}>
      <article>
        <div className="about-top">
          <div className="container-fluid">
            <div className="d-flex flex-wrap align-items-lg-start justify-content-lg-between justify-content-center text-venter">
              <div className="column 6 col-12 d-lg-none d-block order-1">
                <div className="row with-border py-2">
                  <div className="order-lg-1 col-12 pe-lg-5 py-4 py-lg-0 d-flex align-items-start justify-content-evenly flex-lg-column">
                    {isLoadingUnities ? (
                      ""
                    ) : (
                      <>
                        <div className="item mb-lg-4 d-lg-block">
                          <p className="mb-lg-0 me-lg-4 text-center">Desde</p>
                          <h4>
                            {"USD " +
                              Intl.NumberFormat("de-DE").format(
                                getMinAndMaxPrice(allUnitiesData).min
                              )}
                          </h4>
                        </div>
                        <div className="item d-lg-block">
                          <p className="me-lg-4 mb-lg-0 text-center">Hasta</p>
                          <h4>
                            {"USD " +
                              Intl.NumberFormat("de-DE").format(
                                getMinAndMaxPrice(allUnitiesData).max
                              )}
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="column pb-4 py-4 py-lg-4 col-6 col-lg-auto d-none d-lg-block 2 order-lg-2 order-2">
                <i
                  className={`icon-building${iconsStyle} me-lg-0 text-center text-lg-start`}
                ></i>
                <div className="info text-center text-lg-start">
                  <h3 className="mb-2 mb-lg-1">TIPO</h3>
                  <p>{getType(allDevelopmentData)}</p>
                </div>
              </div>
              <div className="column pb-4 py-4 py-lg-4 col-6 col-lg-auto d-block 2 order-lg-2 order-2">
                <i
                  className={`icon-construction_state${iconsStyle} me-lg-0 text-center text-lg-start`}
                ></i>
                <div className="info text-center text-lg-start">
                  <h3 className="mb-2 mb-lg-1">ESTADO</h3>
                  <p>{getStatus(allDevelopmentData)}</p>
                </div>
              </div>
              <div className="column py-4 py-lg-4 col-6 col-lg-auto d-block 1 order-lg-3 order-3">
                <i
                  className={`icon-unit${iconsStyle} me-lg-0 text-center text-lg-start`}
                ></i>
                <div className="info text-center text-lg-start">
                  <h3 className="mb-2 mb-lg-1">UNIDADES DISPONIBLES</h3>
                  {console.log(allUnitiesData)}
                  <p>
                    {isLoadingUnities
                      ? ""
                      : getCountUnities(
                          allUnitiesData?.filter(u => u?.availability?.toLowerCase() === "disponible" || realEstate.keys.tokko)
                        ) || "Sin especificar"}
                  </p>
                </div>
              </div>
              {!realEstate.keys.tokko &&
                allDevelopmentData?.parking_lot?.length > 0 && (
                  <div className="column py-4 py-lg-4 col-6 col-lg-auto d-block 3 order-lg-4 order-4">
                    <i
                      className={`icon-parking${iconsStyle} me-lg-0 text-center text-lg-start`}
                    ></i>
                    <div className="info text-center text-lg-start">
                      <h3 className="mb-2 mb-lg-1">COCHERAS</h3>
                      {!isLoadingUnities
                        ? (allDevelopmentData?.parking_lot).map(park => (
                            <p>
                              {park.parking_lot_amount}{" "}
                              {(park.parking_lot_type !== "" &&
                              park.parking_lot_type.toLowerCase() !==
                                "sin especificar"
                                ? " " +
                                  park.parking_lot_type
                                    ?.split(" ")
                                    ?.map(
                                      text =>
                                        text.toLowerCase() +
                                        (park.parking_lot_amount > 1 ? "s" : "")
                                    )
                                : ""
                              ).replaceAll(",", " ")}
                            </p>
                          ))
                        : ""}
                    </div>
                  </div>
                )}
              <div className="column py-4 py-lg-4 col-6 col-lg-auto d-block 3 order-lg-5 order-5">
                <i
                  className={`icon-location${iconsStyle} me-lg-0 text-center text-lg-start`}
                ></i>
                <div className="info text-center text-lg-start">
                  <h3 className="mb-2 mb-lg-1">UBICACIÓN</h3>
                  <p>
                    {getLocation(allDevelopmentData, true)[0]} <br />{" "}
                    {getLocation(allDevelopmentData, true)[1]}
                  </p>
                </div>
              </div>
              <div className="column py-4 py-lg-4 col-6 col-lg-auto d-block 4 order-lg-5 order-5">
                <i
                  className={`icon-delivery-date${iconsStyle} me-lg-0 text-center text-lg-start`}
                ></i>
                <div className="info text-center text-lg-start">
                  <h3 className="mb-2 mb-lg-1">
                    {getStatus(allDevelopmentData)
                      ?.toLowerCase()
                      ?.includes("terminado")
                      ? "ENTREGADO"
                      : "FECHA DE ENTREGA"}
                  </h3>
                  <p
                    className={
                      getStatus(allDevelopmentData)
                        ?.toLowerCase()
                        ?.includes("terminado") && "d-none"
                    }
                  >
                    {getContructionDate(allDevelopmentData) ||
                      "Sin especificar"}
                  </p>
                </div>
              </div>
              <div className="column col-lg-3 col-12 6 d-lg-block d-none order-9 order-lg-7">
                <div className="row with-border py-2">
                  <div className="col-12 pe-lg-5 py-4 py-lg-0 d-flex align-items-start justify-content-end flex-column">
                    {isLoadingUnities ? (
                      ""
                    ) : (
                      <>
                        <div className="item mb-lg-2 d-flex d-lg-block">
                          <p className="mb-4 mb-lg-0 mr-4">Desde</p>
                          <h4>
                            {"USD " +
                              Intl.NumberFormat("de-DE").format(
                                getMinAndMaxPrice(allUnitiesData).min
                              )}
                          </h4>
                        </div>
                        <div className="item d-flex d-lg-block">
                          <p className="mr-4 mb-lg-0">Hasta</p>
                          <h4>
                            {"USD " +
                              Intl.NumberFormat("de-DE").format(
                                getMinAndMaxPrice(allUnitiesData).max
                              )}
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <div className="concepto">
        <div className="container-fluid">
          <div className="row intro">
            <div className="col-lg-12">
              <Breadcrumbs
                props={[
                  { name: realEstate.name, route: "/", location: "" },
                  {
                    name: "Emprendimientos",
                    route: "/emprendimientos",
                    location: "",
                  },
                  {
                    name: getName(allDevelopmentData),
                    route: "",
                    location: "",
                  },
                ]}
              />
            </div>
            <div
              className={
                "col-12 " + (getTitle(allDevelopmentData) ? "" : "d-none")
              }
            >
              {!MediaPro && (
                <button
                  data-bs-toggle="modal"
                  data-bs-target={"#modal-social-" + allDevelopmentData?.id}
                  className="mt-lg-0 mb-3 buton-share ms-auto d-block d-lg-none"
                >
                  <i className={`me-lg-2 me-1 icon-share${iconsStyle}`}></i>
                  Compartir{" "}
                </button>
              )}
              <div className="row align-items-center">
                <div className="col-lg-2">
                  <h4 className="section-name">Concepto</h4>
                </div>
                <div className="col-lg-10 d-lg-flex justify-content-between align-items-center">
                  <p>{getTitle(allDevelopmentData)}</p>
                  {!MediaPro && (
                    <button
                      data-bs-toggle="modal"
                      data-bs-target={"#modal-social-" + allDevelopmentData?.id}
                      className="mt-lg-0 buton-share d-none d-lg-block"
                    >
                      <i className={`me-lg-2 me-1 icon-share${iconsStyle}`}></i>
                      Compartir{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ubicacion-main">
        <div className="container-fluid">
          <div
            class={
              "row intro" +
              (!MediaPro
                ? "py-5 mt-lg-0 mb-lg-5 pt-lg-0"
                : " py-lg-0 pt-5 pb-0")
            }
          >
            <div className="col-lg-2">
              <h4 className="section-name">Descripción</h4>
            </div>
            <div className="col-lg-10">
              <p className="description-ficha">
                {getDescription(allDevelopmentData, 99999).map(
                  (description, index) => (
                    <>
                      {description}
                      <br />
                    </>
                  )
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {
        <div className="ubicacion-main mt-lg-5 mt-5 mb-lg-0 mb-3">
          <div className="container-fluid">
            <div class={"row"}>
              {[1, 2, 3].map((type_id, index) => (
                <div
                  className={
                    getTags(allDevelopmentData).filter(
                      tag => tag.tag_type === type_id || tag.type === type_id
                    ).length > 0
                      ? ""
                      : "d-none"
                  }
                >
                  <div className="row align-items-start my-lg-5 my-4">
                    <div className="col-lg-2">
                      <h4 className="mb-4 mt-lg-0">
                        {title_extras[index] === "Amenities" &&
                        realEstate.keys.tokko
                          ? "Ambientes"
                          : title_extras[index] === "Generales" &&
                            realEstate.keys.tokko
                          ? "Adicionales"
                          : title_extras[index]}
                      </h4>
                    </div>
                    <div className="col-lg-10">
                      <div className="row no-gutters amenities">
                        {allDevelopmentData &&
                          getTags(allDevelopmentData)
                            .filter(
                              tag =>
                                tag.tag_type === type_id || tag.type === type_id
                            )
                            .map((tag, index) => (
                              <div className="col-lg-3 col-6 mb-3 item align-items-center d-flex">
                                <i className="icon-check"></i>
                                <p>{tag.name}</p>
                              </div>
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }

      {!mediaBar && (
        <>
          <div id="section-galery">
            <div className="" id="dev-secuencial">
              <div className="slider" id="dev-gallery">
                <span className="conPag onTop">
                  {positionSlider} / {getPhotos(allDevelopmentData).length}
                </span>
                <SimpleReactLightbox>
                  <SRLWrapper options={options}>
                    <Slider
                      afterChange={e => setPositionSlider(e + 1)}
                      ref={sliderRef}
                      {...settingsSlick}
                    >
                      {getPhotos(allDevelopmentData).map((photo, index) => (
                        <div className="item banner-main banner-ficha emprendimiento">
                          <img
                            src={getQuality(photo)}
                            className="object-cover object-center cursor-pointer"
                            alt={realEstate?.name}
                          />
                        </div>
                      ))}
                    </Slider>
                    <span
                      onClick={() => dispatch(setTogglerImages())}
                      className="legend-fullscreen"
                    >
                      Abrir galería de imágenes
                    </span>
                  </SRLWrapper>
                </SimpleReactLightbox>
                <div
                  className={
                    "arrows-left onTop zindex-1" +
                    (getPhotos(allDevelopmentData)?.length === 1 ||
                    positionSlider === 1
                      ? " d-none"
                      : "")
                  }
                >
                  <div
                    className="arrow"
                    onClick={() => sliderRef.current.slickPrev()}
                  >
                    {" "}
                    <i></i> <i></i>
                  </div>
                </div>
                <div
                  className={
                    "arrows-right onTop zindex-1" +
                    (getPhotos(allDevelopmentData)?.length === 1 ||
                    positionSlider === getPhotos(allDevelopmentData)?.length
                      ? " d-none"
                      : "")
                  }
                >
                  <div
                    className="arrow"
                    onClick={() => sliderRef.current.slickNext()}
                  >
                    {" "}
                    <i></i> <i></i>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div
              id="video"
              class={
                "row global-view " +
                (getVideos(allDevelopmentData).length === 0 ? "d-none" : "")
              }
            >
              <SliderComponent
                files={getVideos(allDevelopmentData)}
                type={"videos"}
              />
            </div>
            <div
              id="tour360"
              class={
                "row global-view " +
                (getTours(allDevelopmentData).length === 0 ? "d-none" : "")
              }
            >
              <SliderComponent
                files={getTours(allDevelopmentData)}
                type={"tours"}
              />
            </div>
            <div
              id="blueprints"
              class={
                "row global-view " +
                (getBluePrints(allDevelopmentData).length === 0 ? "d-none" : "")
              }
            >
              <SliderComponent
                files={getBluePrints(allDevelopmentData)}
                type={"images"}
              />
            </div>
          </div>
        </>
      )}
      <FilesPro isLoading={isLoading} files={allDevelopmentData?.files} />
      <div className="ubicacion-main mt-lg-5 mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-2 col-12">
                  <h4 className="section-name mb-lg-2">Ubicación</h4>
                </div>
                <div className="col-lg-10 col-12">
                  <p className="mb-4 pb-lg-0 pb-1">
                    {getFakeAddress(allDevelopmentData) +
                      ", " +
                      getLocation(allDevelopmentData, true)[0]}
                    <br />
                    {getLocation(allDevelopmentData, true)[1]}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12"></div>
          </div>
          <div className="row">
            <div className="col-12">
              {typeof window !== "undefined" && MapComponent ? (
                <MapComponent
                  data={allDevelopmentData}
                  coordenadas={{
                    geo_lat: allDevelopmentData?.geo_lat,
                    geo_long: allDevelopmentData?.geo_long,
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default connect(
  state => ({
    development: state.developments.development,
    loading: state.developments.loading,
    unities: state.developments.unities,
  }),
  null
)(About)
