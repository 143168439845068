import React, { useRef, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { connect } from "react-redux"

//Helpers

import { getLocation, getName } from "../../helpers/helper.developments"
import {
  getCover,
  getPhotos,
  getQuality,
  getVideos,
} from "../../helpers/helper.rendering"
import Loading from "../../components/Loading"
import MultiActionBar from "../multi-action-bar"
import Gallery from "../FsLightboxGallery"
import { getBluePrints, getTours } from "../../helpers/helper.properties"
import { graphql, useStaticQuery } from "gatsby"
import { useGetDevelopmentDetailQuery } from "../../redux/middlewareTokkoApi/developments"
import { useGetDevelopmentQuery } from "../../redux/mediacore/emprendimientos"
import useQueryDevelopment from "../../hooks/useQueryDevelopment"
import {
  getActionByPage,
  getActionByType,
  getCodeFromType,
  getCodes,
} from "../../helpers/helper.actions"
import { useGetPushQuery } from "../../redux/mediacore/push"
import OwlCarousel from "react-owl-carousel3"
import { informCustomEvent } from "../../helpers/helper.analytics"

const isBrowser = typeof window !== "undefined"

const Main = ({
  development,
  loading,
  loading_unities,
  togglerImages,
  togglerVideos,
  togglerTours,
  togglerBlueprints,
  currentlyImage,
  currentlyBluePrint,
  id,
}) => {
  const slider = useRef()

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys {
          tokko
        }
        env {
          CLIENTID
        }
      }
    }
  `)

  // const { data: allDevelopmentData, isLoading } = useGetDevelopmentDetailQuery({"API_KEY": realEstate.keys.tokko, "DEVELOPMENT_ID": id});
  const { allDevelopmentData, isLoading } = useQueryDevelopment(id)

  const { data: allPushData, isLoading: isLoadingPush } = useGetPushQuery(
    realEstate.env.CLIENTID
  )

  const getActionById = allSectionActions =>
    allSectionActions.filter(action => action.developments.includes(Number(id)))
      .length === 0
      ? allSectionActions.filter(action => action.developments.length === 0)
      : allSectionActions.filter(action =>
          action.developments.includes(Number(id))
        )

  const slider_action = getActionById(
    getActionByPage(
      getActionByType(
        allPushData,
        getCodeFromType("Slider de Imagenes Encabezado")
      ),
      "FDE"
    )
  )
  const image_action = getActionById(
    getActionByPage(
      getActionByType(allPushData, getCodeFromType("Imagen Encabezado")),
      "FDE"
    )
  )
  const video_action = getActionById(
    getActionByPage(
      getActionByType(allPushData, getCodeFromType("Video Encabezado")),
      "FDE"
    )
  )

  const hasIndivualSliderAction = slider_action.some(action =>
    action.developments.includes(Number(id))
  )

  const hasIndivualImageAction = image_action.some(action =>
    action.developments.includes(Number(id))
  )

  const hasIndivualVideoAction = video_action.some(action =>
    action.developments.includes(Number(id))
  )

  const getSize = () => isBrowser && (screen.width < 992 ? "mobile" : "desktop")

  const goTo = () => {
    if (video_action[0].url_destiny) {
      informCustomEvent(
        "PUSH_" +
          getCodes(video_action[0]?.type, video_action[0]?.section) +
          "_" +
          video_action[0]?.name
      )
      window.open(
        video_action[0].url_destiny + video_action[0].text_utm,
        "_blank",
        "noopener,noreferrer"
      )
    }
  }

  return (
    <section
      className={
        "ficha-main ficha pt-0 emprendimiento " + (isLoading ? "skeleton" : "")
      }
    >
      <article>
        {(!isLoading || !isLoadingPush) &&
          (video_action.length > 0 &&
          !hasIndivualSliderAction &&
          !hasIndivualImageAction &&
          !(
            getSize() === "mobile" &&
            !video_action[0]?.files?.find(video => video.device === "mobile")
          ) ? (
            <div
              className={
                "item " +
                (video_action[0]?.files?.filter(
                  video => video.device === getSize()
                )?.[0]?.file === ""
                  ? "cursor-default"
                  : "cursor-pointer")
              }
              id="dev-gallery"
              style={ getSize() === "desktop" ?{
                height: "calc(100vh)",
              } : {}}
            >
              <video
                onClick={() => goTo()}
                loop
                autoPlay
                muted
                style={
                  getSize() === "mobile" ? {
                    width: "100%",
                  } : {}
                }
              >
                <source
                  src={
                    video_action[0]?.files?.filter(
                      video => video.device === getSize()
                    )?.[0]?.file ?? ""
                  }
                  type="video/mp4"
                />
              </video>
            </div>
          ) : slider_action.length > 0 &&
            !hasIndivualImageAction &&
            !hasIndivualVideoAction ? (
            <div id="section-galery-home">
              <div id="dev-secuencial">
                <div className="slider" id="dev-gallery">
                  <OwlCarousel
                    items={1}
                    ref={slider}
                    margin={0}
                    nav={false}
                    dots={true}
                    loop={true}
                    rewind={true}
                    autoplay={true}
                    autoplayTimeout={
                      parseInt(slider_action[0]?.transition_time) * 1000
                    }
                    id="prop-gallery"
                  >
                    {slider_action[0].files.map((file, index) => (
                      <div
                        className={
                          "item banner-main banner-ficha emprendimiento "
                        }
                        key={index}
                      >
                        <img
                          src={file.file}
                          className="object-cover object-center cursor-pointer"
                          alt={realEstate?.name}
                        />
                        <div className="info-banner position-absolute">
                          <div className="info row align-items-center text-center justify-content-center">
                            <div className="col-8 col-lg-12">
                              <a
                                target={"_blank"}
                                onClick={() =>
                                  informCustomEvent(
                                    "PUSH_" +
                                      getCodes(
                                        slider_action[0].type,
                                        slider_action[0].section
                                      ) +
                                      "_" +
                                      slider_action[0].name
                                  )
                                }
                                href={
                                  file?.url_destiny + slider_action[0].text_utm
                                }
                                className={
                                  "link-gallery " +
                                  (!file.url_destiny && "d-none")
                                }
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                  <div
                    className="arrows-owl"
                    style={
                      slider_action.length > 0 && getSize() === "mobile"
                        ? { top: "40%" }
                        : {}
                    }
                  >
                    <div
                      className="arrow"
                      onClick={() => slider.current.prev()}
                    >
                      <div className="next">
                        <i className="icon-arrow"> </i>
                      </div>
                    </div>
                    <div
                      className="arrow "
                      onClick={() => slider.current.next()}
                    >
                      <div className="next">
                        <i className="icon-arrow"> </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                id="dev-gallery"
                onClick={() =>
                  informCustomEvent(
                    "PUSH_" +
                      getCodes(
                        image_action[0]?.type,
                        image_action[0]?.section
                      ) +
                      "_" +
                      image_action[0]?.name
                  ) + image_action[0]?.url_destiny
                    ? window.open(
                        image_action[0].url_destiny + image_action[0]?.text_utm,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    : ""
                }
                className={
                  "item " + image_action[0]?.url_destiny === ""
                    ? "cursor-default"
                    : "cursor-pointer"
                }
              >
                <img
                  src={
                    image_action.length > 0
                      ? image_action[0]?.files[0].file
                      : getQuality(getCover(allDevelopmentData?.photos))
                  }
                  alt=""
                />
              </div>
            </>
          ))}
        <div
          className="center-text"
          style={
            slider_action.length > 0 && getSize() === "mobile"
              ? { top: "30%" }
              : {}
          }
        >
          <div className="text-center pb-5 pb-lg-0">
            <h3>{getLocation(allDevelopmentData, true)[0]}</h3>
            <h3 className="mb-5 mt-1">
              {getLocation(allDevelopmentData, true)[1]}{" "}
            </h3>
            <h1>DESCUBRI</h1>
            <h1 className="mb-5 mt-1">{getName(allDevelopmentData)}</h1>
          </div>
        </div>
        <MultiActionBar
          skeleton={isLoading}
          data={allDevelopmentData}
          className={
            slider_action.length > 0 && getSize() === "mobile"
              ? "multimediaDiv mobileCondition"
              : "multimediaDiv"
          }
        />
        {getVideos(allDevelopmentData).length > 0 ? (
          <Gallery
            index={1}
            sources={getVideos(allDevelopmentData)}
            slide={1}
            type={"video"}
            toggleEvent={togglerVideos}
            data={allDevelopmentData}
          />
        ) : null}
        {getPhotos(allDevelopmentData).length > 0 ? (
          <Gallery
            index={2}
            sources={getPhotos(allDevelopmentData)}
            slide={currentlyImage}
            type={"image"}
            toggleEvent={togglerImages}
            data={allDevelopmentData}
          />
        ) : null}
        {getTours(allDevelopmentData).length > 0 ? (
          <Gallery
            index={3}
            sources={getTours(allDevelopmentData)}
            slide={1}
            type={"tour"}
            toggleEvent={togglerTours}
            data={allDevelopmentData}
          />
        ) : null}
        {getBluePrints(allDevelopmentData).length > 0 ? (
          <Gallery
            index={4}
            sources={getBluePrints(allDevelopmentData)}
            slide={currentlyBluePrint}
            type={"blueprint"}
            toggleEvent={togglerBlueprints}
            data={allDevelopmentData}
          />
        ) : null}
      </article>
    </section>
  )
}

export default connect(
  state => ({
    development: state.developments.development,
    loading: state.developments.loading,
    loading_unities: state.developments.loading_unities,
    api_key: state.settings.keys.tokko,
    currentlyImage: state.properties.currentlyImage,
    currentlyBluePrint: state.properties.currentlyBluePrint,
    togglerImages: state.properties.togglerImages,
    togglerVideos: state.properties.togglerVideos,
    togglerTours: state.properties.togglerTours,
    togglerBlueprints: state.properties.togglerBlueprints,
  }),
  null
)(Main)
