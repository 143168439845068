export const getCountUnities = unities => {
  if (unities.length > 0) {
    return unities.length
  }
}
export const getMinAndMaxPrice = unities => {
  if(unities[0]?.operations[0]?.prices){
    const prices = unities.map(unity => unity?.operations[0]?.prices[0]?.price ? unity?.operations[0]?.prices[0]?.price : unity?.operations[0]?.prices?.price)
    let min = 0
    let max = 0
    min = Math.min(...prices)
    max = Math.max(...prices)
    return { min, max }
  }
  else{
    return {min:"0",max:"0"}
  }
}

export const findEnvironments = (unities, shortResponse = false) => {
  const array = []
  let filteresUnities = []
  if (!unities || unities === {}) {
    return array
  }

  if (unities?.length > 0) {
    if (shortResponse) {
        for (const iterator of unities) {
          if (
            iterator.real_address.split("-")[0].toLowerCase().includes("barrio") ||
            iterator.real_address.split("-")[0].toLowerCase().includes("dúplex") ||
            iterator.real_address.split("-")[0].toLowerCase().includes("piso") ||
            iterator.real_address
              .split("-")[0]
              .toLowerCase()
              .includes("subsuelo") ||
            iterator.real_address
              .split("-")[0]
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes("area") ||
            iterator.real_address.split("-")[0].toLowerCase().includes("nivel")
          ) {
            filteresUnities.push(iterator)
          }
        }
      }
      else{
        filteresUnities = unities
      }
    for (let i = 1; i <= 10; i++) {
      const find = filteresUnities?.find(
        element => element?.room_amount === i
      )?.room_amount
      if (find) {
        array.push(find)
      }
    }
  }
  return array
}

export const getUnitiesWithCorrectRealAddress = unities => {
  //   if (typeof unities === "object") {
  //     return []
  //   }
  const response = []
  if (!unities) {
    return response
  }
  for (const iterator of unities) {
    if (
      iterator.real_address.split("-")[0].toLowerCase().includes("barrio") ||
      iterator.real_address.split("-")[0].toLowerCase().includes("dúplex") ||
      iterator.real_address.split("-")[0].toLowerCase().includes("piso") ||
      iterator.real_address.split("-")[0].toLowerCase().includes("subsuelo") ||
      iterator.real_address
        .split("-")[0]
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes("area") ||
      iterator.real_address.split("-")[0].toLowerCase().includes("nivel")
    ) {
      response.push(iterator)
    }
  }
  return response
}
