import { useStaticQuery, graphql } from "gatsby"
import { useGetDevelopmentDetailQuery as useGetDevelopmentsTokkoQuery, useGetUnitiesQuery } from "../redux/middlewareTokkoApi/developments"
import { useGetDevelopmentQuery as useGetDevelopmentsMediacoreQuery } from "../redux/mediacore/emprendimientos"

function useQueryUnities(id) {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys {
          tokko
        }
        env {
          CLIENTID
        }
      }
    }
  `)

  let {
    data: allUnitiesData,
    isLoading,
    isError,
    isFetching
  } = realEstate.keys.tokko //EDIT
    ? useGetUnitiesQuery({"API_KEY": realEstate.keys.tokko, "DEVELOPMENT_ID": id})
    : useGetDevelopmentsMediacoreQuery({
        CLIENT_ID: realEstate.env.CLIENTID,
        DEVELOPMENT_ID: id
      })

      if(!realEstate.keys.tokko){
        allUnitiesData = allUnitiesData?.properties
      }

      console.log(allUnitiesData)

  return { allUnitiesData, isLoading, isError, isFetching, isMediacore: (!realEstate.keys.tokko ? true : false) }//EDIT
}

export default useQueryUnities
